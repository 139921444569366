@tailwind base;

@tailwind components;

@tailwind utilities;

@keyframes marquee-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes marquee-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.animate-marquee-up {
  animation: marquee-up 10s linear infinite;
}

.animate-marquee-down {
  animation: marquee-down 10s linear infinite;
}
